<template>
    <div class="dashboard-container" v-loading="loading">
        <div style="margin-bottom: 20px;margin-top: 20px;margin-right: 20px;">
            <el-row>

                <el-col :span="24" align="right">
                    <el-button  type="primary" size="mini"  @click="addsaleRecord">添加</el-button>
                </el-col>

            </el-row>
        </div>
        <!-- 添加或编辑类别 -->
        <el-dialog :title="title" :visible="showDialog" @close="btnCancel">
            <!-- 表单 -->
            <el-form ref="perForm" :model="bmsSaleRecordDto" :rules="rules" label-width="120px">

                <el-form-item label="销售负责人名称" prop="username">
                    <el-input v-model="bmsSaleRecordDto.username" style="width: 50%" />
                </el-form-item>
                <el-form-item label="地区" prop="area">
                    <el-input v-model="bmsSaleRecordDto.area" style="width: 50%" />
                </el-form-item>
                <el-form-item label="联系电话" prop="tel">
                    <el-input v-model="bmsSaleRecordDto.tel" style="width: 50%" />
                </el-form-item>
                <el-form-item label="销售价格" prop="salePrice">
                    <el-input v-model="bmsSaleRecordDto.salePrice" style="width: 50%" />
                </el-form-item>
              
                <el-form-item label="记录时间" prop="recordDate">
                    <el-date-picker v-model="bmsSaleRecordDto.recordDate" type="date" @change="selectChange1"
                        value-format="yyyy-MM-dd" placeholder="选择日期">

                    </el-date-picker>
                </el-form-item>
                <!-- <el-form-item label="检测报告图片" prop="checkFiles">
            <el-upload
              class="upload-demo"
              :data="postData1"
              list-type="picture-card"
              :file-list="fileList1"
              show-file-list
              :on-success="handleavatarsuccess1"
              :before-upload="beforeUpload1"
              action="http://up.qiniup.com"
              :on-remove="handleRemove1"
              multiple
            >
              <i class="el-icon-plus"></i>
            </el-upload>
          </el-form-item> -->
          <el-form-item label="检测报告图片" prop="checkFiles">
          <el-upload class="upload-demo" :data="postData1" list-type="picture-card" :file-list="fileList1"
            show-file-list :on-success="handleavatarsuccess1" :before-upload="beforeUpload1"
            action="http://up.qiniup.com" :on-remove="handleRemove1" multiple>
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>


               
        


       
        <el-form-item label="销售文件信息"  prop="saleFileDtoList">
          <el-upload class="upload-demo" ref="up" action="http://up.qiniup.com" :show-file-list="true"
          :on-preview="handlePreview"
          list-type="picture"
          :file-list="fileList"
            accept="" :on-progress="uploadVideoProcess"  :on-remove="handleRemovesp1"
            :on-success="handleAvatarSuccesssp1" :data="postData2" :before-upload="beforeAvatarUploadsp1"
            :disabled="loadProgress == 0 ? false : loadProgress == 101 ? false : true">
            <el-button type="primary" :disabled="loadProgress == 0 ? false : loadProgress == 101 ? false : true">点击上传
            </el-button>
          
          </el-upload>

        </el-form-item>

            </el-form>
            <el-row slot="footer" type="flex" justify="center">
                <el-col :span="6">
                    <el-button size="small" type="primary" @click="btnOK">
                        确定
                    </el-button>
                    <el-button size="small" @click="btnCancel">取消</el-button>
                </el-col>
            </el-row>
        </el-dialog>

        <!-- -------万恶分割线------- -->



        <!-- 主体内容 -->

        <div class="tab">
            <el-table :header-cell-style="{ background: '#F2F6FC', color: '#606266' }" :data="list" border
                style="width: 100%" ref="list" class="emp_table" v-if="list">
                <el-table-column align="center" prop="title" label="名称" :show-overflow-tooltip="true" />
                <el-table-column align="center" prop="username" label="监察员名称" :show-overflow-tooltip="true" />
                <el-table-column align="center" prop="tel" label="联系电话" :show-overflow-tooltip="true" />
                <el-table-column align="center" prop="recordDate" label="记录时间" :show-overflow-tooltip="true" />




                <el-table-column align="center" label="操作">
                    <template slot-scope="scope">

                        <el-button type="text" size="small" @click="updateManagerUser(scope.row)">
                            编辑
                        </el-button>

                        <el-button type="text" size="small" @click="delsaleRecord(scope.row)">
                            删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <pagination v-show="total > 0" :total="total" :page.sync="getRecordListDto.pageNum"
                :limit.sync="getRecordListDto.pageSize" @pagination="saleRecordList" />
        </div>
    </div>
</template>
    
<script>
import {
    getUploadToken,
    saleRecordList,
    delsaleRecord,
    addsaleRecord,
    updatesaleRecord,
} from "../../request/http";

export default {
    data() {
        return {
            loadProgress:0,
            fileType1:null,
            res: [],
            title: "",
            loading: true,//加载动画
            showDialog: false,
            list: "",
            total: 0,
            getRecordListDto: {
                pageNum: 1,
                pageSize: 10,
                companyId: null,
                contractId: null
            },
         
            fileList1: [],
          
      fileList2: [],
      fileList: [],
      postData1: {
        token: "", //上传凭证
        fid: "", //文件id
        fileDomain: "" //文件域名
      },
      postData2: {
        token: "", //上传凭证
        fid: "", //文件id
        fileDomain: "" //文件域名
      },
          
       
            dialogVisible: false, //显示添加或编辑框
            bmsSaleRecordDto: {
        contractId: null,
        saleFileDtoList: [],
        checkFiles: [],
        recordDate: "",
        username: "",
        tel: "",
        salePrice: null,
        area: '',
      },
           

            rules: {

                saleFileDtoList: [
                    { required: true, message: "销售记录文件不能为空", trigger: "blur" },
                ],
                recordDate: [
                    { required: true, message: "销售时间不能为空", trigger: "blur" },
                ],
               
                area: [
                    { required: true, message: "销售地区不能为空", trigger: "blur" },
                ],
                checkFiles: [
                    { required: true, message: "检测报告图片不能为空", trigger: "blur" },
                ],
                username: [
                    { required: true, message: "销售人负责人姓名不能为空", trigger: "blur" },
                ],
                salePrice: [
                    { required: true, message: "销售价格不能为空", trigger: "blur" },
                ],
                tel: [
                    { required: true, message: "销售人负责人电话不能为空", trigger: "blur" },
                    {
                        pattern:
                            /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/,
                        message: "请输入正确的手机号码",
                    },
                ],


            },
        };
    },

    created() {
        this.getRecordListDto.companyId = this.$route.query.companyId;
        this.getRecordListDto.contractId = this.$route.query.contractId;
        this.bmsSaleRecordDto.contractId = this.$route.query.contractId;
        this.saleRecordList();
    },

    mounted() { },
    methods: {
        handlePreview(file) {
     console.log(file);
     let key;

file.key ? key = file.key.fid : key = file.response.key
       


        window.open('http://farmfile.uutele.com/' + key, '_blank')
       
      } ,
        selectChange1(value) {
            this.bmsSaleRecordDto.recordDate = value
            console.log(this.bmsSaleRecordDto.recordDate);
        },
         //上传图片
    async beforeUpload2(file) {
      let { data } = await getUploadToken();
      console.log(data);
      this.postData2.token = data.data.token;
      this.postData2.fid = data.data.fid;
      this.postData2.fileDomain = data.data.fileDomain;
    },
    uploadVideoProcess(event, file, fileList) {
      this.loadProgress = parseInt(event.percent); // 动态获取文件上传进度
      console.log(this.loadProgress);
    },

    //上传图片成功回调函数
    handleavatarsuccess2(response, file, fileList) {
      console.log(response, file, fileList);
     
      this.bmsSaleRecordDto.saleFileDtoList.push({
        fid: response.key,
        fileType: 1
      });
      console.log(this.bmsSaleRecordDto.saleFileDtoList);
      this.$message.success("上传图片成功");
    },
    //删除多张图片的一张
    handleRemove2(file) {
      let key;

      file.key ? key = file.key : key = file.response.key


      let i = this.bmsSaleRecordDto.saleFileDtoList.findIndex((item) => item.fid === key)

      console.log(key, i);
      // console.log(i);
      //  this.bmsSaleRecordDto.saleFileDtoList=  this.bmsSaleRecordDto.saleFileDtoList.filter(item => item.fid!==key)
      this.bmsSaleRecordDto.saleFileDtoList.splice(i, 1);
      console.log(this.bmsSaleRecordDto.saleFileDtoList);

    },


    //上传图片
    async beforeUpload1(file) {
      let { data } = await getUploadToken();
      console.log(data);
      this.postData1.token = data.data.token;
      this.postData1.fid = data.data.fid;
      this.postData1.fileDomain = data.data.fileDomain;
    },
    //上传图片成功回调函数
    handleavatarsuccess1(response, file, fileList) {
      console.log(response, file, fileList);
      console.log(this.bmsSaleRecordDto.checkFiles);
      this.bmsSaleRecordDto.checkFiles.push(response.key);
      this.$message.success("上传图片成功");
    },
    //删除多张图片的一张
    handleRemove1(file) {
      let key;

      file.key ? key = file.key : key = file.response.key


      let i = this.bmsSaleRecordDto.checkFiles.indexOf(key);

      console.log(key, i);
      // console.log(i);
      //  this.bmsSaleRecordDto.saleFileDtoList=  this.bmsSaleRecordDto.saleFileDtoList.filter(item => item.fid!==key)
      this.bmsSaleRecordDto.checkFiles.splice(i, 1);
      console.log(this.bmsSaleRecordDto.checkFiles);

    },
    handleRemovesp1(file) {
      console.log(file);
      let key;

      file.key ? key = file.key.fid : key = file.response.key

      let i = this.bmsSaleRecordDto.saleFileDtoList.findIndex((item) => item.fid === key)

      // let i = this.bmsSaleRecordDto.saleFileDtoList.indexOf(key);

      console.log(key, i);
      // console.log(i);
      //  this.bmsSaleRecordDto.files=  this.bmsSaleRecordDto.files.filter(item => item.fid!==key)
      this.bmsSaleRecordDto.saleFileDtoList.splice(i, 1);
      console.log(this.bmsSaleRecordDto.saleFileDtoList);

    },
    // 上传方法
    handleAvatarSuccesssp1(response, file, fileList) {
        console.log('上传成功', response,file.raw.type)
      if(file.raw.type.includes('image')){
        this.bmsSaleRecordDto.saleFileDtoList.push({
        fid: response.key,
        fileType: 1
      });
      }
      else if (file.raw.type.includes('video')){
        this.bmsSaleRecordDto.saleFileDtoList.push({
        fid: response.key,
        fileType: 2
      });
      file.url="http://farmfile.uutele.com/" + response.key+'?vframe/jpg/offset/0'
      }
     
      this.loadProgress = this.loadProgress + 1
   
      console.log(this.bmsSaleRecordDto.saleFileDtoList);
    
    },
    async beforeAvatarUploadsp1(file) {
      this.loadProgress = 0
      let { data } = await getUploadToken()
      if (data == '' || data == null) {
        this.message.error('上传文件时获取Token失败！')
        return
      }
      this.postData2.token = data.data.token;
      this.postData2.fid = data.data.fid;
      this.postData2.fileDomain = data.data.fileDomain;
    },
  
//         //上传图片
//         async beforeUpload(file) {
//             let { data } = await getUploadToken();
//             console.log(data);
//             this.postData.token = data.data.token;
//             this.postData.fid = data.data.fid;
//             this.postData.fileDomain = data.data.fileDomain;
//         },
//         //上传图片成功回调函数
//         handleavatarsuccess(response, file, fileList) {
//             console.log(response, file, fileList);
//             console.log(this.bmsSaleRecordDto.saleFileDtoList);
//             this.bmsSaleRecordDto.saleFileDtoList.push(response.key);
//             this.$message.success("上传图片成功");
//         },
//         //删除多张图片的一张
//         handleRemove(file) {
//             let key;

//             file.key ? key = file.key : key = file.response.key


//             let i = this.bmsSaleRecordDto.saleFileDtoList.indexOf(key);

//             console.log(key, i);
//             // console.log(i);
//             //  this.bmsSaleRecordDto.saleFileDtoList=  this.bmsSaleRecordDto.saleFileDtoList.filter(item => item.fid!==key)
//             this.bmsSaleRecordDto.saleFileDtoList.splice(i, 1);
//             console.log(this.bmsSaleRecordDto.saleFileDtoList);

//         },


//   //上传图片
//   async beforeUpload1(file) {
//             let { data } = await getUploadToken();
//             console.log(data);
//             this.postData1.token = data.data.token;
//             this.postData1.fid = data.data.fid;
//             this.postData1.fileDomain = data.data.fileDomain;
//         },
//         //上传图片成功回调函数
//         handleavatarsuccess1(response, file, fileList) {
//             console.log(response, file, fileList);
//             console.log(this.bmsSaleRecordDto.checkFiles);
//             this.bmsSaleRecordDto.checkFiles.push(response.key);
//             this.$message.success("上传图片成功");
//         },
//         //删除多张图片的一张
//         handleRemove1(file) {
//             let key;

//             file.key ? key = file.key : key = file.response.key


//             let i = this.bmsSaleRecordDto.checkFiles.indexOf(key);

//             console.log(key, i);
//             // console.log(i);
//             //  this.bmsSaleRecordDto.saleFileDtoList=  this.bmsSaleRecordDto.saleFileDtoList.filter(item => item.fid!==key)
//             this.bmsSaleRecordDto.checkFiles.splice(i, 1);
//             console.log(this.bmsSaleRecordDto.checkFiles);

//         },


        goback() {
            this.$router.go(-1)
        },

        //
        async saleRecordList() {
            let { data } = await saleRecordList(this.getRecordListDto);
            let list = data.data;
            console.log(list);
          


            if (data.code == 1) {
                this.list = []
      }
      else {
        this.list = list.records;

this.total = list.total;

      }
      this.loading = false
        },

        // 打开添加的窗口
        addsaleRecord() {
            this.title = "添加销售记录";

            this.showDialog = true;
        },
        // 打开编辑类别的窗口
        updateManagerUser(val) {
            // 根据获取id获取详情
            this.title = "编辑销售记录";
            var images = val.saleFileDtoList
           
            var img =val.checkFiles
   
   
            for (let i = 0; i < images.length; i++) {
                        if(images[i].fileType==1){
                            this.fileList.push({
                    url: "http://farmfile.uutele.com/" + images[i].fid,
                    name:'图片',
                    key: images[i],
                });
                }    else if (images[i].fileType==2){
                    this.fileList.push({
                    url: "http://farmfile.uutele.com/" + images[i].fid+'?vframe/jpg/offset/0',
                    name:'视频',
                    key: images[i],
                });
                }
                 
              
                }

         




            for (let i = 0; i < img.length; i++) {
                this.fileList1.push({
                    url: "http://farmfile.uutele.com/" + img[i],
                    key: img[i],
                });
            }
          
            console.log(val);
           
                this.bmsSaleRecordDto.id = val.id,
                this.bmsSaleRecordDto.contractId = val.contractId,
                this.bmsSaleRecordDto.saleFileDtoList = val.saleFileDtoList,
                this.bmsSaleRecordDto.checkFiles = val.checkFiles,
                this.bmsSaleRecordDto.recordDate = val.recordDate,
                this.bmsSaleRecordDto.username = val.username,
                this.bmsSaleRecordDto.tel = val.tel,
                this.bmsSaleRecordDto.area = val.area,
                this.bmsSaleRecordDto.salePrice = val.salePrice,
          
                
                img,
             



                this.showDialog = true;
        },
        // 确认
        async btnOK() {
            this.$refs.perForm.validate().then(async () => {
                if (this.bmsSaleRecordDto.id) {
                    let { data } = await updatesaleRecord(this.bmsSaleRecordDto);
                    if (this.bmsSaleRecordDto.id && data.code == 0) {
                        this.$message.success("编辑成功");
                    } else {
                        console.log(data.code);
                        this.$message({
                            type: "error",
                            message: data.code,
                        });
                    }
                    this.saleRecordList();

                    this.showDialog = false;
                } else {
                    let { data } = await addsaleRecord(this.bmsSaleRecordDto);
                    console.log(data);
                    if (data.code == 0) {
                        this.$message.success("新增成功");
                    } else {
                        console.log(data.msg);
                        this.$message({
                            type: "error",
                            message: data.msg,
                        });
                    }
                    this.saleRecordList();

                    this.showDialog = false;
                }
            });
        },
        //弹窗取消函数
        btnCancel() {
            this.bmsSaleRecordDto = { contractId: this.$route.query.contractId,
                saleFileDtoList: [],
                checkFiles:[],
                recordDate: "",
                username: "",
                tel: "",
                salePrice:null,
                area:'',
            };
            this.loadProgress=0,
                this.fileType1=0
                this.fileList=[]
                this.fileList2=[]
                this.fileList1=[]
                this.fileList3=[]
            this.$refs.perForm.resetFields();
            this.showDialog = false;
        },

        async delsaleRecord(row) {
            try {
                await this.$confirm("确定要删除该销售记录吗");
                console.log(row);
                let id = row.id;
                let delRecordDto={
                    contractId: this.$route.query.contractId,
                    recordId:id
                }
                let { data } = await delsaleRecord(delRecordDto);

                if (data.code == 0) {
                    this.$message({
                        type: "success",
                        message: "删除成功",
                    });
                } else {
                    this.$message({
                        type: "error",
                        message: data.msg,
                    });
                }
                const totalPage = Math.ceil(
                    (this.total - 1) / this.getRecordListDto.pageSize
                );
                this.getRecordListDto.pageNum =
                    this.getRecordListDto.pageNum > totalPage ? totalPage : this.getRecordListDto.pageNum;
                this.getRecordListDto.pageNum =
                    this.getRecordListDto.pageNum < 1 ? 1 : this.getRecordListDto.pageNum;

                this.saleRecordList();
            } catch (error) {
                console.log(error);
            }
        },
    },
};
</script>
    
<style scoped>

</style>
    